import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchPostsRoutine } from '../../../services/routines/posts';
import { currentLanguageCodeSelector } from '../../../services/selectors/languageSelectors';
import { allPostsSelector } from '../../../services/selectors/postsSelectors';

import BlogSection from './BlogSection';

const mapStateToProps = createStructuredSelector({
  currentLanguage: currentLanguageCodeSelector,
  posts: allPostsSelector
});

const mapDispatchToProps = {
  onFetchPosts: fetchPostsRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogSection);
