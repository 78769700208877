import { Locale } from '../utils/enums/languages';

import cms from './cms';

const authorQuery = `
{
  _id,
  'name': authorName.[$language]
}
`;

const mainImageQuery = `
asset->{
  _id,
  url
}
`;

const translationUrlQuery = `
defined(__i18n_refs) => __i18n_refs[0]->{
  'lang': __i18n_lang,
  'slug': slug.current
},
defined(__i18n_base) => __i18n_base->{
  'lang': __i18n_lang,
  'slug': slug.current
}
`;

const postQuery = `
_id,
'lang': __i18n_lang,
publishedAt,
title,
slug,
excerpt,
body,
author->${authorQuery},
mainImage{
  ${mainImageQuery}
},
'translationUrl': {
  ${translationUrlQuery}
}
`;

/**
 * API helper for getting a list of blog posts
 * @param {Locale} [language=Locale.SI]
 * @returns {Promise<Object[]>}
 */
export const getPosts = async (language = Locale.SI) => {
  return cms.fetch(
    `
    *[_type == "post" && __i18n_lang == $language] | order(_createdAt desc){
      ${postQuery}
    }
    `,
    { language }
  );
};

/**
 * API helper for getting a list of recent blog posts. Defaults to the 3 most recent blog posts.
 * @param {Number} [amount=3]
 * @param {Locale} [language=Locale.SI]
 * @returns {Promise<Object[]>}
 */
export const getRecentPosts = async (amount = 3, language = Locale.SI) => {
  return cms.fetch(
    `
    *[_type == "post" && __i18n_lang == $language] | order(_createdAt desc){
      ${postQuery}
    }[0...$amount]
    `,
    { amount, language }
  );
};

/**
 * API helper for getting a blog post for given slug
 * @param {String} slug
 * @param {Locale} [language=Locale.SI]
 * @returns {Promise<Object[]>}
 */
export const getPostBySlug = async (slug, language = Locale.SI) => {
  return cms.fetch(
    `
    *[_type == "post" && slug.current == $slug]{
      ${postQuery}
    }
    `,
    { slug, language }
  );
};
