import { createSelector } from 'reselect';

import { getMappedStateIds } from '../../utils/helpers/redux';
import { paramsFromMatchPropsSelector } from './globalSelectors';

const getState = (state) => state.posts;

export const postsAllIdsSelector = createSelector(
  [getState],
  (state) => state.allIds
);

export const postsByIdSelector = createSelector(
  [getState],
  (state) => state.byId
);

export const allPostsSelector = createSelector(
  [postsAllIdsSelector, postsByIdSelector],
  getMappedStateIds
);

export const postsIsFetchingSelector = createSelector(
  [getState],
  (state) => state.isFetching
);

export const postsLastFetchedSelector = createSelector(
  [getState],
  (state) => state.lastFetched
);

export const postsShouldFetchSelector = createSelector(
  [postsIsFetchingSelector, postsLastFetchedSelector],
  (isFetching, lastFetched) => !lastFetched && !isFetching
);

export const postForSlugParamPropSelector = createSelector(
  [allPostsSelector, paramsFromMatchPropsSelector],
  (posts, params) => {
    return posts.find((post) => post.slug.current === params.slug);
  }
);
