import { createLastFetchedReducer } from '../../../utils/helpers/redux';

import {
  fetchPostBySlugRoutine,
  fetchPostsRoutine
} from '../../routines/posts';
import { LOCALIZE_SET_ACTIVE_LANGUAGE } from '../../types/languages';

const requestCases = [
  fetchPostsRoutine.FAILURE,
  fetchPostBySlugRoutine.FAILURE,
  fetchPostsRoutine.SUCCESS,
  fetchPostBySlugRoutine.SUCCESS
];

const resetCases = [LOCALIZE_SET_ACTIVE_LANGUAGE];

/**
 * Posts Reducers: Last Fetched
 */
const postsLastFetchedReducer = createLastFetchedReducer(
  requestCases,
  resetCases
);

export default postsLastFetchedReducer;
