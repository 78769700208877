import { createRoutine } from 'redux-saga-routines';

import { FETCH_POSTS, FETCH_POST_BY_SLUG } from '../types/posts';

/**
 * Fetch posts routine
 */
export const fetchPostsRoutine = createRoutine(FETCH_POSTS);

/**
 * Fetch post by slug routine
 */
export const fetchPostBySlugRoutine = createRoutine(FETCH_POST_BY_SLUG);
