import { createIsFetchingReducer } from '../../../utils/helpers/redux';

import {
  fetchPostBySlugRoutine,
  fetchPostsRoutine
} from '../../routines/posts';

const endCases = [
  fetchPostsRoutine.FAILURE,
  fetchPostsRoutine.SUCCESS,
  fetchPostBySlugRoutine.FAILURE,
  fetchPostBySlugRoutine.SUCCESS
];

const requestCases = [
  fetchPostsRoutine.REQUEST,
  fetchPostBySlugRoutine.REQUEST
];

/**
 * Posts Reducers: Is Fetching
 */
const postsIsFetchingReducer = createIsFetchingReducer({
  endCases,
  requestCases
});

export default postsIsFetchingReducer;
