import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import { SubpagesHero } from '../../components/Hero';
import { ImageSection } from '../../components/Sections';
import { Container, Columns } from '../../components/Helpers';

import projects_page from '../../content/projects_page.json';
import { Link } from 'react-router-dom';

import './Projects.scss';

class Projects extends Component {
  componentDidMount() {
    let title = projects_page[this.props.currentLanguage].page_title.replace(
      /[^a-zA-Z0-9-_\s]/g,
      ''
    );
    document.title = `${title} | Optifarm`;
    ReactGA.set({ title: document.title, page: document.title });
  }
  render() {
    const projects_page_data = projects_page[this.props.currentLanguage];
    const { projects } = projects_page_data;

    return (
      <div className="projects-page">
        <SubpagesHero
          data={projects_page_data}
          hero_image={projects_page_data.featured_image}
        />
        <div className="subpage-main-content">
          <div className="subpage-section">
            <Container>
              <Columns className="is-multiline is-mobile persist-order">
                {projects.map(
                  ({ title, intro_paragraph, featured_image, slug }) => (
                    <Link to={projects_page_data.slug + slug}>
                      <div className="column is-one-third-widescreen is-one-quarter-desktop is-one-third-tablet is-full-mobile">
                        <div class="project">
                          <div className="content">
                            <img src={featured_image} alt="" />
                            <h3 className="title is-4">{title}</h3>
                            <p className="subtitle is-6">{intro_paragraph}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                )}
              </Columns>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: getActiveLanguage(state.localize).code
});

export default connect(mapStateToProps)(Projects);
