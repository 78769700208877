import { combineReducers } from 'redux';

import postsIsFetchingReducer from './isFetchingReducer';
import postsLastFetchedReducer from './lastFetchedReducer';
import postsAllIdsReducer from './postsAllIdsReducer';
import postsByIdReducer from './postsByIdReducer';

const postsRootReducer = combineReducers({
  allIds: postsAllIdsReducer,
  byId: postsByIdReducer,
  isFetching: postsIsFetchingReducer,
  lastFetched: postsLastFetchedReducer
});

export default postsRootReducer;
