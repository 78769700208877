import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { currentLanguageCodeSelector } from '../../../services/selectors/languageSelectors';

import BlogPostCard from './BlogPostCard';

const mapStateToProps = createStructuredSelector({
  currentLanguage: currentLanguageCodeSelector
});

export default connect(mapStateToProps)(BlogPostCard);
