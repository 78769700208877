import { setActiveLanguage } from 'react-localize-redux';
import { call, put, select } from 'redux-saga/effects';

import * as api from '../../../api/posts';
import { sagaWatcher } from '../../../utils/helpers/redux';

import {
  fetchPostBySlugRoutine,
  fetchPostsRoutine
} from '../../routines/posts';
import { currentLanguageCodeSelector } from '../../selectors/languageSelectors';
import { history } from '../../../store';
import { push } from 'connected-react-router';

/**
 * Fetch posts saga
 * @returns {Generator}
 */
export function* fetchPostsSaga({ payload }) {
  yield put(fetchPostsRoutine.request());

  try {
    const language = yield select(currentLanguageCodeSelector);

    const apiCall = payload?.onlyRecent ? api.getRecentPosts : api.getPosts;
    const apiArgs = payload?.onlyRecent ? [3, language] : [language];
    const response = yield call(apiCall, ...apiArgs);

    if (response) {
      yield put(fetchPostsRoutine.success(response));
      return;
    }
  } catch (err) {
    yield put(fetchPostsRoutine.failure(err));
  }
}

export function* fetchSinglePostSaga({ payload }) {
  yield put(fetchPostBySlugRoutine.request());

  try {
    const language = yield select(currentLanguageCodeSelector);
    const response = yield call(api.getPostBySlug, payload, language);

    if (response) {
      // Handling the case where we get one post back, and that post
      // is not in the currently selected language
      if (response.length === 1 && response[0].lang !== language) {
        // if the post is translated, we will get post.translationUrl.slug back. In that
        // case, we can fetch that post and keep the currently selected language
        const post = response[0];
        if (post.translationUrl.slug) {
          const translatedResponse = yield call(
            api.getPostBySlug,
            post.translationUrl.slug,
            post.translationUrl.lang
          );
          yield put(fetchPostBySlugRoutine.success(translatedResponse));
          yield put(push(`/blog/${post.translationUrl.slug}`));
          return;
        } else {
          // if the post is not translated, we resort to switching the language to the language of the post
          yield put(setActiveLanguage(post.lang));
          yield put(fetchPostBySlugRoutine.success(response));
          return;
        }
      } else {
        // if we get multiple posts back that means that we already got them in both locale
        // (the slug is the same). We want to filter for the post with the current locale.
        const filteredPost = response.filter((post) => post.lang === language);
        yield put(fetchPostBySlugRoutine.success(filteredPost));
        return;
      }
    }
  } catch (err) {
    yield put(fetchPostBySlugRoutine.failure(err));
  }
}

/**
 * Root vats saga
 * @returns {IterableIterator}
 */
export default function* postsSagas() {
  yield sagaWatcher([
    {
      type: fetchPostsRoutine.TRIGGER,
      saga: fetchPostsSaga
    },
    {
      type: fetchPostBySlugRoutine.TRIGGER,
      saga: fetchSinglePostSaga
    }
  ]);
}
