import React from 'react';
import { usePrevious } from 'react-use';

import {
  Container,
  Columns,
  SectionStarter
} from '../../../components/Helpers';
import { BlogPostCard } from '../../../components/Cards';

import {
  getBlogPostBaseUrl,
  getBlogPostExcerpt
} from '../../../utils/helpers/blogs';

/**
 * Component rendering Blog Posts section
 * @param {Object} props
 * @param {Object} props.data
 * @returns {JSX.Element}
 */
const BlogSection = ({
  data: { front_page },
  currentLanguage,
  onFetchPosts,
  posts
}) => {
  const prevLang = usePrevious(currentLanguage);

  const fetchPosts = React.useCallback(() => {
    onFetchPosts({ onlyRecent: true });
  }, [onFetchPosts]);

  React.useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  React.useEffect(() => {
    if (currentLanguage !== prevLang) {
      fetchPosts();
    }
  }, [currentLanguage, prevLang, fetchPosts]);

  return (
    <section id="blog">
      <SectionStarter />
      <Container>
        <h2 className="section-title">{front_page.title}</h2>
        <h3 className="section-subtitle">{front_page.subtitle}</h3>
        <p className="section-excerpt">{front_page.excerpt}</p>
        <Columns className="blogposts">
          {posts?.map((post) => (
            <BlogPostCard
              {...post}
              key={post._id}
              excerpt={getBlogPostExcerpt(post)}
              base_url={getBlogPostBaseUrl()}
            />
          ))}
        </Columns>
      </Container>
    </section>
  );
};

export default BlogSection;
