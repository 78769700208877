import BlockContent from '@sanity/block-content-to-react';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Redirect } from 'react-router-dom';

import { getImageUrl } from '../../api/images';
import { config } from '../../api/cms';

import { Container } from '../../components/Helpers';
import { MainBtn, OutlinedBtn } from '../../components/Buttons';
import { SubpagesHero } from '../../components/Hero';
import { SanityTable } from '../../components/Sanity';

import { getBlogPostBaseUrl } from '../../utils/helpers/blogs';
import {
  getBackToHomeLabel,
  getBackToBlogPostsLabel
} from '../../utils/helpers/strings';

import WireframeTemplate from './WireframeTemplate';

class SingleBlogPost extends Component {
  constructor(props) {
    super(props);
    this.setPageTitle = this.setPageTitle.bind(this);
    this.serializers = {
      types: {
        table: SanityTable
      }
    };
  }

  setPageTitle() {
    const { post } = this.props;
    document.title = `${post?.title} | Optifarm`;
    ReactGA.set({ title: document.title, page: document.title });
  }

  componentDidMount() {
    this.setPageTitle();

    if (this.props.shouldFetch) {
      this.props.onFetchPost();
    }
  }

  componentDidUpdate({ currentLanguage, post }) {
    if (
      currentLanguage !== this.props.currentLanguage &&
      post?.translationUrl
    ) {
      // If the language has changed, we want to do the following:
      // - grab the translationUrl slug from the previous post object
      // - redirect to the new slug
      // - fetch the post for the new slug
      const { slug } = post.translationUrl;
      this.props.history.push(`/blog/${slug}`);
      this.props.onFetchPostRequest(slug);
    }
    if (post?.title !== this.props.post?.title) {
      this.setPageTitle();
    }
  }

  render() {
    const { post, currentLanguageObject, loading, shouldFetch } = this.props;
    const isNotFound = !shouldFetch && !loading && !post;

    if (shouldFetch || loading) {
      return <WireframeTemplate />;
    }

    if (isNotFound) {
      return <Redirect to="/404" />;
    }

    return (
      <div className="single-blog-post">
        <SubpagesHero
          hero_image={getImageUrl(post.mainImage, 1200)}
          data={post}
        />
        <section id="single-post">
          <Container className="blog-post-container content">
            <BlockContent
              blocks={post.body}
              projectId={config.projectId}
              dataset={config.dataset}
              serializers={this.serializers}
            />
            <hr />
            <div className="cta-container">
              <OutlinedBtn
                href="/"
                text={getBackToHomeLabel(currentLanguageObject)}
                type="primary"
              />
              <MainBtn
                href={`${getBlogPostBaseUrl()}`}
                text={getBackToBlogPostsLabel(currentLanguageObject)}
              />
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

export default SingleBlogPost;
