import { getEntitiesByIdFromListResponse } from '../../../utils/helpers/redux';

import {
  fetchPostBySlugRoutine,
  fetchPostsRoutine
} from '../../routines/posts';

const postsByIdReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case fetchPostsRoutine.SUCCESS:
    case fetchPostBySlugRoutine.SUCCESS: {
      return getEntitiesByIdFromListResponse(action.payload);
    }

    default: {
      return state;
    }
  }
};

export default postsByIdReducer;
