import React from 'react';

import { getImageUrl } from '../../../api/images';

import { useCurrentLanguage } from '../../../hooks';
import { isCurrentLanguageEn } from '../../../utils/helpers/languages';

import { MainBtn } from '../../Buttons';

import { BlogPostCardMeta } from './components';

/**
 * Component rendering blog post preview card
 * @param {Object} props
 * @param {Object} props.author
 * @param {Object[]} props.body
 * @param {string} props.title
 * @param {string} props.mainImage
 * @param {string} props.currentLanguage
 * @param {Object} props.meta
 * @param {string} props.excerpt
 * @param {string} props.base_url
 * @param {Object} props.slug
 * @returns {JSX.Element}
 */
const BlogPostCard = ({
  author,
  body,
  title,
  mainImage,
  currentLanguage,
  excerpt,
  base_url,
  slug
}) => {
  const language = useCurrentLanguage();

  const readMoreText = React.useMemo(() => {
    return isCurrentLanguageEn(language) ? 'Read more' : 'Preberi več';
  }, [language]);

  return (
    <div className="column card blogpost-card">
      <div className="card-image">
        <img src={getImageUrl(mainImage)} alt={title} loading="lazy" />
      </div>

      <div className="card-content">
        <h3 className="card-title">{title}</h3>

        <BlogPostCardMeta
          author={author}
          body={body}
          currentLanguage={currentLanguage}
        />

        <p className="card-excerpt">{excerpt}</p>

        <div className="cta-container">
          <MainBtn href={`${base_url}/${slug.current}`} text={readMoreText} />
        </div>
      </div>
    </div>
  );
};

export default BlogPostCard;
