import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { localizeReducer } from 'react-localize-redux';

import postsReducer from './postsReducer';

const rootReducer = (history) => {
  return combineReducers({
    localize: localizeReducer,
    posts: postsReducer,
    router: connectRouter(history)
  });
};

export default rootReducer;
