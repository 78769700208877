import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchPostsRoutine } from '../../services/routines/posts';
import { currentLanguageSelector } from '../../services/selectors/languageSelectors';
import {
  allPostsSelector,
  postsShouldFetchSelector
} from '../../services/selectors/postsSelectors';

import Blog from './Blog';

const mapStateToProps = createStructuredSelector({
  currentLanguage: currentLanguageSelector,
  posts: allPostsSelector
});

const mapDispatchToProps = { onFetchPosts: fetchPostsRoutine };

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
