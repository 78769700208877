import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import { SubpagesHero } from '../../components/Hero';
import { ImageSection } from '../../components/Sections';
import { Container, Columns } from '../../components/Helpers';

import projects_data from '../../content/projects_data.json';
import { Link } from 'react-router-dom';

import '../Projects/Projects.scss';

class Projects extends Component {
  componentDidMount() {
    let title = projects_data[this.props.currentLanguage].page_title.replace(
      /[^a-zA-Z0-9-_\s]/g,
      ''
    );
    document.title = `${title} | Optifarm`;
    ReactGA.set({ title: document.title, page: document.title });
  }
  render() {
    const projects_page_data = projects_data[this.props.currentLanguage];
    const { projects } = projects_page_data;

    return (
      <div className="project-page">
        <SubpagesHero
          data={projects_page_data}
          hero_image={projects_page_data.featured_image}
        />
        <div className="subpage-main-content">
          <div className="subpage-section">
            <Container>
              <div className="is-multiline is-mobile persist-order">
                <img
                  src={projects_page_data.featured_image}
                  alt={projects_page_data.title}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: projects_page_data.paragraph1
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: projects_page_data.paragraph2
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: projects_page_data.paragraph3
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: projects_page_data.paragraph4
                  }}
                />
                <h2>{projects_page_data.title1}</h2>
                <p>{projects_page_data.paragraph5}</p>
                <p>{projects_page_data.paragraph6}</p>
                <p>{projects_page_data.paragraph7}</p>
                <p>{projects_page_data.paragraph8}</p>
                <h2>{projects_page_data.title2}</h2>
                <p>{projects_page_data.paragraph9}</p>
                <p>
                  {projects_page_data.paragraph10}
                  <ul>
                    <li>{projects_page_data.paragraph11}</li>
                    <li>{projects_page_data.paragraph12}</li>
                    <li>{projects_page_data.paragraph13}</li>
                    <li>{projects_page_data.paragraph14}</li>
                    <li>{projects_page_data.paragraph15}</li>
                    <li>{projects_page_data.paragraph16}</li>
                    <li>{projects_page_data.paragraph17}</li>
                    <li>{projects_page_data.paragraph18}</li>
                  </ul>
                </p>
                <h2>{projects_page_data.title3}</h2>
                <ul>
                  <li>{projects_page_data.paragraph19}</li>
                  <li>{projects_page_data.paragraph20}</li>
                  <li>{projects_page_data.paragraph21}</li>
                  <li>{projects_page_data.paragraph22}</li>
                </ul>
                <h2>{projects_page_data.title4}</h2>
                <ul>
                  <li>{projects_page_data.paragraph23}</li>
                  <li>{projects_page_data.paragraph24}</li>
                  <li>{projects_page_data.paragraph25}</li>
                  <li>{projects_page_data.paragraph26}</li>
                  <li>{projects_page_data.paragraph27}</li>
                </ul>
                <h2>{projects_page_data.title5}</h2>
                <ul>
                  <li>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: projects_page_data.paragraph28
                      }}
                    />
                  </li>
                  <li>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: projects_page_data.paragraph29
                      }}
                    />
                  </li>
                </ul>
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: getActiveLanguage(state.localize).code
});

export default connect(mapStateToProps)(Projects);
