import { take, spawn } from 'redux-saga/effects';

/**
 * Returns merged current and new state object
 * @param {ReduxState} state
 * @param {Object} newState
 * @returns {ReduxState}
 */
export const mergeWithCurrentStateObject = (state, newState) => ({
  ...state,
  ...newState
});

/**
 * Returns merged current and new state array
 * @param {Array} state
 * @param {Array} newState
 * @returns {Array}
 */
export const mergeWithCurrentStateArray = (state, newState) => [
  ...state,
  ...newState
];

/**
 * Returns merged current state and new state. Based on the passed state
 * merged result can be either array or an object.
 * @param {Array|Object} state
 * @param {Array|Object} newState
 * @returns {Array|Object}
 */
export const mergeWithCurrentState = (state, newState) => {
  if (Array.isArray(state)) {
    return mergeWithCurrentStateArray(state, newState);
  }

  return mergeWithCurrentStateObject(state, newState);
};

/**
 * Transform actions types into lookup dictionary
 * @param {string[]} [types=[]]
 * @returns {Object}
 */
export const createActionTypeMap = (types = []) =>
  types.reduce((acc, type) => ({ ...acc, [type]: true }), {});

/**
 * Transform actions types into lookup dictionary with isMatch and shouldReplaceState
 * flags
 * @param {Object[]} types
 * @returns {Object}
 */
export const createActionTypeMapWithReplaceStateFlag = (types) => {
  return types.reduce(
    (acc, type) => ({
      ...acc,
      [type.type]: {
        isMatch: true,
        shouldReplaceState: Boolean(type.shouldReplaceState)
      }
    }),
    {}
  );
};

/**
 * Boilerplate function for creating isDoing reducer
 * @param {Object} options
 * @param {string[]} options.endCases
 * @param {string[]} options.requestCases
 * @returns {import('redux').Reducer}
 */
export const createIsDoingReducer = ({ endCases, requestCases }) => {
  const endCasesMap = createActionTypeMap(endCases);
  const requestCasesMap = createActionTypeMap(requestCases);

  return (state = false, { type }) => {
    // This is either SUCCESS or FAIL, it means that the action is
    // no longer taking place
    if (endCasesMap[type]) {
      return false;
    }

    // Most often, this is PENDING, it means that the action is currently
    // taking place
    if (requestCasesMap[type]) {
      return true;
    }

    // We return current state if action does not match any of the cases
    return state;
  };
};

/**
 * Boilerplate function for creating isFetching reducer
 * @param {Object} options
 * @param {string[]} options.endCases
 * @param {string[]} options.requestCases
 * @returns {ReduxReducer}
 */
export const createIsFetchingReducer = createIsDoingReducer;

/**
 * Boilerplate function for creating error reducer
 * @param {string[]} failureCases
 * @param {string[]} resetCases
 * @returns {ReduxReducer}
 */
export const createErrorReducer = (failureCases, resetCases) => {
  const failureCasesMap = createActionTypeMap(failureCases);
  const resetCasesMap = createActionTypeMap(resetCases);

  return (state = null, { type, payload }) => {
    // If we hit the failure case, we return the payload (error) of the
    // failure action
    if (failureCasesMap[type]) {
      return payload;
    }

    // If we hit the reset case, we return the initial state, which is
    // null
    if (resetCasesMap[type]) {
      return null;
    }

    // We return current state if action does not match end case(s)
    return state;
  };
};

/**
 * Boilerplate function for creating allIds reducer from pageable API response
 * @param {Object[]} successCases
 * @param {string[]} [resetStateCases]
 * @returns {import('redux').Reducer}
 */
export const createAllIdsReducer = (successCases, resetStateCases) => {
  const successCasesMap = createActionTypeMapWithReplaceStateFlag(successCases);
  const resetStateCasesMap = createActionTypeMap(resetStateCases);

  return (state = [], { type, payload }) => {
    // If we hit success case, we want to return array of entities IDs
    if (successCasesMap[type]?.isMatch) {
      let ids = [];

      if (Array.isArray(payload)) {
        // We fetched multiple entities
        ids = payload.map((entity) => entity._id);
      } else {
        // We fetched single entity in this case
        ids.push(payload._id);
      }

      // If we need to replace the current state, we just want to return
      // the array of currently obtained IDs
      if (successCasesMap[type]?.shouldReplaceState) {
        return ids;
      }

      // Otherwise, we merge existing IDs with new IDs
      return [...new Set([...state, ...ids])];
    }

    if (resetStateCasesMap[type]) {
      // Return empty array on reset state cases
      return [];
    }

    // We return current state if action does not match success cases
    return state;
  };
};

/**
 * Boilerplate function for creating lastFetched reducer
 * @param {ReduxType[]} requestCases
 * @param {ReduxType[]} resetCases
 * @returns {import('redux').Reducer}
 */
export const createLastFetchedReducer = (requestCases, resetCases) => {
  const requestCasesMap = createActionTypeMap(requestCases);
  const resetCasesMap = createActionTypeMap(resetCases);

  return (state = null, { type }) => {
    // If we hit the fetch case, we want to set the timestamp to the current time
    if (requestCasesMap[type]) {
      return Date.now();
    }

    if (resetCasesMap[type]) {
      return null;
    }

    return state;
  };
};

/**
 * Returns object from Pageable API response in a single object
 * with entity.id as a key for a given entity
 * @param {Object[]} payload
 * @returns {Object}
 */
export const getEntitiesByIdFromListResponse = (payload) => {
  return payload.reduce(
    (acc, entity) => ({ ...acc, [entity._id]: entity }),
    {}
  );
};

/**
 * Given list of ids and map of entities by id, returns list of entities
 * @param {Id[]} allIds
 * @param {Object.<Id, Object>} byId
 * @returns {Object[]}
 */
export const getMappedStateIds = (allIds = [], byId = {}) => {
  return allIds.map((id) => byId[id]);
};

/**
 * Creates a Saga watcher for given sagas
 * @param {Object[]} sagasToWatch
 * @return {IterableIterator}
 */
export function* sagaWatcher(sagasToWatch) {
  while (true) {
    const action = yield take(sagasToWatch.map((saga) => saga.type));

    const sagaToWatch = sagasToWatch.find((saga) => saga.type === action.type);

    yield spawn(sagaToWatch.saga, action);
  }
}
