import { createAllIdsReducer } from '../../../utils/helpers/redux';

import {
  fetchPostBySlugRoutine,
  fetchPostsRoutine
} from '../../routines/posts';

const successTypes = [
  { type: fetchPostsRoutine.SUCCESS, shouldReplaceState: true },
  { type: fetchPostBySlugRoutine.SUCCESS, shouldReplaceState: true }
];

/**
 * Posts All Ids reducer
 */
const postsAllIdsReducer = createAllIdsReducer(successTypes);

export default postsAllIdsReducer;
