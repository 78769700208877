import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Route, Switch, withRouter } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';

// Fake database
import footer from './content/footer.json';
import about_page from './content/about_page.json';
import blog_page from './content/blog_page.json';
import contact_page from './content/contact.json';
import services_page from './content/services.json';
import projects_page from './content/projects_page.json';
import project_page from './content/projects_data.json';

// Load screens
import Home from './screens/Home';
import About from './screens/About';
import Contact from './screens/Contact';
import Services from './screens/Services';
import { Blog } from './screens/Blog';
import BlogPost from './screens/BlogPost';
import { NotFound } from './screens/NotFound';
import Projects from './screens/Projects';
import ProjectPage from './screens/ProjectPage';
import { PrivacyPolicy } from './screens/PrivacyPolicy';

// Load components
import Header from './components/Header';
import Footer from './components/Footer';

class App extends Component {
  onBackButtonEvent(e) {
    e.preventDefault();
  }

  componentDidMount() {
    let html = document.querySelector('html');
    html.setAttribute('lang', this.props.currentLanguage);
    window.onpopstate = this.onBackButtonEvent.bind(this);
    ReactGA.pageview(window.location.pathname);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
    let html = document.querySelector('html');
    html.setAttribute('lang', this.props.currentLanguage);
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    const { currentLanguage } = this.props;

    if (!currentLanguage) {
      return null;
    }

    return (
      <div id="app">
        <Header />
        <main className={`is-${currentLanguage}`}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route
              exact
              path={`/${about_page[currentLanguage].slug}`}
              component={About}
            />
            <Route
              exact
              path={`/${blog_page[currentLanguage].slug}`}
              component={Blog}
            />
            <Route
              exact
              path={`/${services_page[currentLanguage].slug}`}
              component={Services}
            />
            <Route
              exact
              path={`/${projects_page[currentLanguage].slug}`}
              component={Projects}
            />
            <Route
              path={`/${project_page[currentLanguage].slug}`}
              component={ProjectPage}
            />
            <Route path={'/blog/:slug'} component={BlogPost} />
            <Route
              exact
              path={`/${contact_page[currentLanguage].slug}`}
              component={Contact}
            />
            <Route exact={true} component={NotFound} />
          </Switch>
        </main>
        <Footer data={footer[this.props.currentLanguage]} />
      </div>
    );
  }
}

const mapStateToProps = ({
  router: {
    location: { pathname }
  },
  localize
}) => ({
  pathname,
  currentLanguage: localize ? getActiveLanguage(localize)?.code : null
});

export default compose(withRouter, connect(mapStateToProps))(App);
