import { createSelector } from 'reselect';

export const propsSelector = (_state, props) => props;

export const matchPropSelector = createSelector(
  [propsSelector],
  (props) => props.match
);

export const paramsFromMatchPropsSelector = createSelector(
  [matchPropSelector],
  (match) => match?.params
);
