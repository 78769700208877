/**
 * Base action type for fetching blog posts
 * @constant
 */
export const FETCH_POSTS = '@posts/FETCH_POSTS';

/**
 * Base action type for fetching blog posts
 * @constant
 */
export const FETCH_POST_BY_SLUG = '@posts/FETCH_POST_BY_SLUG';
