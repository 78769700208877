import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchPostBySlugRoutine } from '../../services/routines/posts';

import {
  currentLanguageSelector,
  currentLanguageCodeSelector
} from '../../services/selectors/languageSelectors';
import {
  postForSlugParamPropSelector,
  postsIsFetchingSelector,
  postsShouldFetchSelector
} from '../../services/selectors/postsSelectors';

import SingleBlogPost from './SingleBlogPost';

const mapStateToProps = createStructuredSelector({
  currentLanguageObject: currentLanguageSelector,
  currentLanguage: currentLanguageCodeSelector,
  loading: postsIsFetchingSelector,
  post: postForSlugParamPropSelector,
  shouldFetch: postsShouldFetchSelector
});

const mapDispatchToProps = {
  onFetchPostRequest: fetchPostBySlugRoutine
};

const mergeProps = (stateProps, dispatchProps, componentProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...componentProps,
  onFetchPost: () =>
    dispatchProps.onFetchPostRequest(componentProps.match.params.slug)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SingleBlogPost);
