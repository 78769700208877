import React from 'react';
import { usePrevious } from 'react-use';

import { BlogPostCard } from '../../components/Cards';
import { Container, Columns, SectionStarter } from '../../components/Helpers';
import { SubpagesHero } from '../../components/Hero';

import blogPage from '../../content/blog_page.json';

import { getBlogPostBaseUrl } from '../../utils/helpers/blogs';
import { setPageTitleAttribute } from '../../utils/helpers/seo';

const Blog = ({ currentLanguage, posts, onFetchPosts }) => {
  const blogPostBaseUrl = getBlogPostBaseUrl(currentLanguage);
  const prevLanguageCode = usePrevious(currentLanguage?.code);

  const data = React.useMemo(() => {
    return blogPage[currentLanguage.code];
  }, [currentLanguage]);

  React.useEffect(() => {
    if (!currentLanguage?.code) {
      return;
    }

    setPageTitleAttribute(blogPage[currentLanguage.code].page_title);
  }, [currentLanguage]);

  React.useEffect(() => {
    onFetchPosts();
  }, [onFetchPosts]);

  React.useEffect(() => {
    if (currentLanguage?.code !== prevLanguageCode) {
      onFetchPosts();
    }
  }, [currentLanguage, prevLanguageCode, onFetchPosts]);

  return (
    <div className="blog-page">
      <SubpagesHero data={data} hero_image={data.featured_image} />
      <SectionStarter />
      <div className="subpage-main-content">
        <Container className="blog-container">
          <Columns className="blogposts is-multiline">
            {posts.map((post) => (
              <div key={post._id} className="column is-one-third">
                <BlogPostCard {...post} base_url={blogPostBaseUrl} />
              </div>
            ))}
          </Columns>
        </Container>
      </div>
    </div>
  );
};

export default Blog;
